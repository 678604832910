<template>
  <div class="main">
    <div class="header">
      <div class="left">
        <button
          style="background-color: rgb(16, 39, 46)"
          class="left-button"
          @click="ToMap()"
        >
          返回
        </button>
      </div>
      <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
      <div class="header-right">
        <div class="time">{{ time }}</div>
        <div class="avatar">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <!-- <img src="../assets/home/avatar.png" alt="" slot="reference" /> -->
            <img
              style="width: 2.5rem; border-radius: 50%; border-color: black"
              src="../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 预览和监控录像 -->
    <div class="monitor">
      <!--   -->
      <div class="videoBtnFlex">
        <div>
          <div class="flex">
            <!-- <button class="preview playbtn" @click="previewClick()">
              预览
            </button>
            <button class="playback playbtn" @click="playbackClick()">
              回放
            </button> -->
            <button v-for="(item,i) in list" :key="i" 
            :class="index==i?'btnColor  playbtn':'playbtn'"
            @click="previewBack(i)">{{ item }}</button>
          </div>
            <iframe
              class="iframe"
              :src="src"
              frameborder="0"
              ref="iframe"
              allowfullscreen="true"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
            ></iframe>
         
        </div>

        <!-- 通道选择 -->
        <div class="channalBox">
          <div class="channalTitle">选择通道播放</div>
          <div v-for="(item, i) in channalList" :key="i">
            <button :class="channalIndex==item?'btnColor elBtn playbtn':'elBtn  playbtn'  " @click="selectChannalClick(item)">通道{{ item }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      // 当前时间
      time: "",
      //  视频地址
      src: "",
      data: "",
      deviceId: "",
      // 播放回放
      list:['预览','回放'],
      // 预览回放index
      index:0,
      // 通道数组
      channalList: [1, 2, 3, 4],
      // 监控通道号
      channalNo: 1,
      // 通道index
      channalIndex:1,
    };
  },
  created() {
    let that = this;
    // @ts-ignore
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
    this.deviceId = this.$route.query.id;
  },
  mounted() {
    // if(this.deviceId==14){
    //   this.src = 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/AH4403548/1.hd.live&accessToken=ar.3eqp2ucnco283fd16rz5rhwka0v8jxw1-2h7wemsn70-1jj08t1-lyzvlljmf&id=ysopen'
    // }else{
    this.requestPlayUrl(this.channalNo);
    // }
  },
  methods: {
    // 获取预览和回放地址
    requestPlayUrl(i) {
      var param = {
        deviceId: this.deviceId,
        scene: 1,
        videoLevel: 2,
        recordType: 1,
        channelNo: i,
      };
      this.$post("/deviceCamera/loadVideo", param)
        .then((res) => {
          // 主要参数
          //   console.log(res);
          if (res.code == 1) {
            console.log(res.data);
            this.data = res.data;
            // const myIframe = document.getElementsByTagName("iframe")[0];

            // const iframeWindow = myIframe.contentWindow;
            // const iframeDocument = iframeWindow.document;
            // const h1Elements = iframeDocument.document.body;
            // // h1Elements.style.backgroundColor = "red";
            // console.log(h1Elements)
            this.src = res.data.previewUrl;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 选择视频通道
    selectChannalClick(i){
      this.channalIndex = i;
      this.index=0
      console.log(this.channalIndex)
      this.requestPlayUrl(i)
    },
    // 预览回放
    previewBack(i){
      this.index = i;
      // 预览
      if(i==0){
        this.src = this.data.previewUrl;
        // 回放
      }else if(i==1){
        this.src = this.data.playbackUrl;
      }
    },
    // 预览
    previewClick() {
      // if(this.deviceId==14){
      //   this.src = 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/AH4403548/1.hd.live&accessToken=ar.3eqp2ucnco283fd16rz5rhwka0v8jxw1-2h7wemsn70-1jj08t1-lyzvlljmf&id=ysopen'
      // }else{
      this.src = this.data.previewUrl;
      // }
    },
    // 回放
    playbackClick() {
      // if(this.deviceId==14){
      //   this.src = 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/AH4403548/1.rec&accessToken=ar.8jq8uvjea0u87rp15eq8i46t297ck0v1-3nr7n8kawq-0ikbyd9-is3yccp5e&id=ysopen'
      // }else{
      this.src = this.data.playbackUrl;
      // }
    },

    // 返回按钮
    ToMap() {
      this.$router.push("/Citydevice");
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 退出登录
    logout() {
      //   window.sessionStorage.clear();
      //   this.clearCookie();
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 退出登录取消
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
  <style>
.audio-controls-left #recordvideo {
  display: none;
}
iframe span#webExpend {
  display: none;
}
</style>
<style lang="scss" scoped>
.main {
  background: url("../assets/drive/big-screen-backimage.svg");
  margin: 0px; //外边距0
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #07080e;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  color: #fff;

  //   头部
  .header {
    display: flex;

    .left {
      position: absolute;
      top: 3.5vh;
      left: 1.563vw;
      display: flex;
      align-items: center;

      .left-button {
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        // background: #000;
        color: rgb(195, 226, 249);
        font-size: 1.04vw;
        border-radius: 7px;
        padding: 0 0.52vw;
        white-space: nowrap;
        box-sizing: border-box;
      }
    }

    .title {
      width: 100%;
      text-align: center;
      // margin-left: -0.5vw;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.4vw;
      cursor: pointer;
    }

    .header-right {
      position: absolute;
      right: 1.563vw;
      top: 3.5vh;
      width: 25%;
      display: flex;
      align-items: center;

      .time {
        // margin-left: 20px;
        width: 80vw;
        text-align: right;
        color: rgb(195, 226, 249);
        font-family: PingFang;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .avatar {
        margin: 0 0 0 15px;
        width: 10vw;
      }
    }
  }

  // 预览和回放
  .monitor {
    position: absolute;
    top: 9vh;
    width: 100vw;
    text-align: center;
    .btnColor{
      background: #ecf5ff;
      color: #6b9ffc;
    }
    .videoBtnFlex {
      display: flex;
      justify-content: space-around;
    }
    .iframe {
      width: 70vw;
      height: 80vh;
      border: 1px solid rgb(192, 135, 13);
      background: #000000;
    }
  }
  // 通道集合
  .channalBox {
    margin-top: 5vh;
    // 选择通道播放标题
    .channalTitle{
      margin: 1vh auto;
      font-size: 20px;
    }
    .elBtn{
      margin: 1vh auto;
    }
  }
  .flex {
    // margin: 20px auto;
    margin-bottom: 10px;
    text-align: center;
  }
    
    .playbtn {
      width: 60px;
      height: 40px;
      text-align: center;
      overflow: hidden;
      border-radius: 5px;
      margin: 0 30px;
    }
  
}
</style>
  
  